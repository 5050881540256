@import './../../../styles/variables.module.scss';

.trendingArticleItemWrapper {
  padding: var(--wds-space-300) 0;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  flex: 1;

  @include small-screen() {
    padding: var(--wds-space-400) 0 var(--wds-space-200);
  }

  @include mobile-only() {
    padding: var(--wds-space-400) 0 var(--wds-space-200);
  }

  .trendingArticleItemLink {
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    text-decoration: none;
    line-height: 0;

    > a {
      overflow: hidden;
      width: 100%;
    }

    .trendingArticleItemTitle,
    .trendingArticleItemArrow {
      color: var(--wds-color-black-100) !important;
    }

    &:hover,
    &:focus {
      .trendingArticleItemTitle,
      .trendingArticleItemArrow {
        color: var(--wds-color-blue-100) !important;
      }
    }
  }

  .trendingArticleItemLabel {
    &:hover,
    &:focus {
      background-color: var(--wds-color-black-200);
      border-color: var(--wds-color-black-200);

      > span {
        color: var(--wds-color-white) !important;
      }
    }
    > span {
      @include rtl() {
        font-size: $rtl-label-font-size;
      }
    }
  }

  .trendingArticleItemTitleWrapper {
    width: 100%;
    overflow: hidden;
    padding-top: var(--wds-space-100);

    .trendingArticleItemTitle {
      @include Header5Bold();

      @include small-screen() {
        @include Header6Bold();
      }

      @include mobile-only() {
        @include Header6Bold();
      }
    }

    .trendingArticleItemArrow {
      flex-shrink: 0;

      @include rtl() {
        transform: scale(-1, 1);
      }
    }
  }
}
