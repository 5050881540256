@import '../../styles/variables.module.scss';

.BlogsSectionWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  background-color: var(--wds-color-white);
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);
  gap: var(--wds-space-200);

  .title {
    @include Header1Bold();

    @include small-screen() {
      @include Header3Bold();
    }

    @include mobile-only() {
      @include Header3Bold();
    }
  }

  .subtitle {
    font-size: 18px !important;

    @include mobile-only() {
      font-size: 16px !important;
    }
  }

  .BlogsSectionItems {
    margin-top: var(--wds-space-500);

    @include small-screen() {
      margin-top: var(--wds-space-300);
    }

    @include mobile-only() {
      margin-top: var(--wds-space-400);
    }
  }

  @include small-screen() {
    padding: var(--wds-space-400) var(--wds-space-700) var(--wds-space-400)
      var(--wds-space-700);
    gap: var(--wds-space-100);
  }

  @include mobile-only() {
    padding: var(--wds-space-400);
    gap: var(--wds-space-100);
  }

  .blogItem {
    max-width: 277.5px;
    width: calc((100% - 90px) / 4);

    @include small-screen() {
      max-width: 360px;
      width: calc((100% - 30px) / 2);
    }

    @include mobile-only() {
      max-width: 100%;
      width: 100%;
    }

    img {
      border-radius: 12px;
      height: 155px;

      @include small-screen() {
        height: 200px;
      }

      cursor: pointer;
    }

    .blogName {
      margin-top: 18px;
      text-decoration: none;
      height: 48px;
      direction: ltr;

      :hover {
        color: var(--wds-color-blue-50);
      }
    }

    .authorName {
      margin-top: 6px;
      text-decoration: none;

      color: var(--wds-color-black-300);

      a {
        color: var(--wds-color-black-300);
      }

      a:hover {
        color: var(--wds-color-blue-50);
      }
    }
  }

  .linkToBlogSite {
    margin-top: var(--wds-space-200);

    :hover {
      color: var(--wds-color-blue-50);
    }

    justify-content: flex-end;

    @include small-screen() {
      justify-content: start;
      margin-top: var(--wds-space-300);
    }

    @include mobile-only() {
      justify-content: start;
      margin-top: var(--wds-space-400);
    }
  }
}
