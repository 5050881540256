.SiteSelectorList {
    max-height: 240px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: var(--wds-space-200);

    &.scroll {
        padding-bottom: 0;

        .SiteSelectorItem {
            .SiteSelectorItemContent {
                padding-inline-end: var(--wds-space-100);
            }
        }
    }

    .loadingMoreSites {
        padding: var(--wds-space-300) 0;

        .loadingMoreSitesText {
            padding-top: var(--wds-space-200);
        }
    }

    .SiteSelectorItem {
        .SiteSelectorItemContent {
            height: 60px;
            padding: 0;
            padding-inline-end: var(--wds-space-400);
            padding-inline-start: var(--wds-space-400);
            cursor: pointer;

            &:hover,
            &:focus {
                background-color: #eaf7ff;
            }

            .siteImage {
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                background-size: cover;
                background-position: 50%;
                box-sizing: border-box;
                margin-inline-end: var(--wds-space-200);
            }

            &.selected {
                background-color: #f4fafe;

                &:hover,
                &:focus {
                    background-color: #eaf7ff;
                }
            }
        }
    }

    .divider {
        padding: 0 var(--wds-space-400);
    }
}