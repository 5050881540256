@import './../styles/variables.module.scss';

.wrapper {
  width: 100%;

  &.newHomepage {
    background-color: var(--wds-color-white);
  }

  .contentWrapper {
    padding-top: 84px;
    @include mobile-only() {
      padding-top: var(--wds-space-400);
    }
    &.siteSelector {
      padding-top: var(--wds-space-700);
    }
  }
}
