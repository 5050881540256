@import '../../styles/variables.module.scss';

.exploreMoreResourcesWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);

  @include small-screen() {
    padding: var(--wds-space-400) var(--wds-space-700) var(--wds-space-400)
      var(--wds-space-700);
  }

  @include mobile-only() {
    padding: var(--wds-space-400);
  }

  .exploreMoreResourcesTitle {
    @include Header1Bold();

    @include small-screen() {
      @include Header3Bold();
    }

    @include mobile-only() {
      @include Header3Bold();
    }
  }

  .exploreMoreResourcesDescriptionWrapper {
    padding: var(--wds-space-100) 0 var(--wds-space-700);

    @include small-screen() {
      padding: var(--wds-space-100) 0 var(--wds-space-400);
    }

    @include mobile-only() {
      padding: var(--wds-space-100) 0 var(--wds-space-400);
    }
  }

  .exploreMoreResourcesDescription {
    font-size: 18px !important;

    @include mobile-only() {
      font-size: 16px !important;
    }
  }

  .exploreMoreResourcesItemsWrapper {
    width: 100%;
    flex-wrap: wrap;
    border: 1px solid var(--wds-color-black-600);
    border-radius: var(--wds-space-300);
    overflow: hidden;

    > .exploreMoreResourcesItemWrapper {
      flex: 1;
      padding: var(--wds-space-400);
      flex-basis: 252px;
      border-inline-end: 1px solid var(--wds-color-black-600);
      box-sizing: border-box;
      overflow: hidden;

      &:last-child {
        border-inline-end: none;
      }

      @include small-screen() {
        flex-basis: calc(50% - 2 * var(--wds-space-400));
        border-top: 1px solid var(--wds-color-black-600);

        &:first-child,
        &:nth-child(2) {
          border-top: none;
        }

        &:nth-child(2n) {
          border-inline-end: none;
        }
      }

      @include mobile-only() {
        flex-basis: 100%;
        border-top: 1px solid var(--wds-color-black-600);
        border-inline-end: none;

        &:first-child {
          border-top: none;
        }
      }
    }

    .excludeBlogItem {
      @include small-screen() {
        flex-basis: calc(30% - 2 * var(--wds-space-400));
        border-top: none;

        &:nth-child(2n) {
          border-inline-end: 1px solid var(--wds-color-black-600);
        }
      }
    }
  }
}
