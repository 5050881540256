@import '../../styles/variables.module.scss';

.RoadmapBannerWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);
  box-sizing: border-box;

  @include small-screen() {
    padding: var(--wds-space-400) var(--wds-space-700) var(--wds-space-400)
      var(--wds-space-700);
  }

  @include mobile-only() {
    padding: var(--wds-space-400);
  }

  .RoadmapBanner {
    background-color: #223246;
    border-radius: var(--wds-space-300);
    box-sizing: border-box;
    padding: var(--wds-space-1400) var(--wds-space-400);

    .SupportedLanguagesDisclaimer {
      padding-top: var(--wds-space-700);

      @include mobile-only() {
        padding-top: var(--wds-space-300);
      }

      text-align: center;
    }

    @include small-screen() {
      padding: var(--wds-space-1500) var(--wds-space-400);
    }

    @include mobile-only() {
      padding: var(--wds-space-800) var(--wds-space-400);
    }
  }

  .roadMapHeading {
    text-align: center;
    @include Header2Bold();

    @include small-screen() {
      @include Header3Bold();
    }

    @include mobile-only() {
      @include Header3Bold();
    }
  }

  .roadmapDescription {
    padding: var(--wds-space-100) 0 var(--wds-space-400);
    text-align: center;

    @include small-screen() {
      padding: var(--wds-space-100) 0 var(--wds-space-300);
    }

    @include mobile-only() {
      padding: var(--wds-space-100) 0 var(--wds-space-300);
    }
  }
}
