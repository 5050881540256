@import '../../styles/variables.module.scss';

.marketplaceEntryWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);

  @include small-screen() {
    padding: var(--wds-space-400) var(--wds-space-700) var(--wds-space-400)
      var(--wds-space-700);
  }

  @include mobile-only() {
    padding: var(--wds-space-400);
    padding-bottom: var(--wds-space-800);
  }

  .marketplaceEntryTitle {
    @include Header1Bold();
    margin-top: var(--wds-space-400);
    margin-bottom: 10px;
    padding-inline-end: var(--wds-space-200);
    height: auto;
    line-break: auto;
    white-space: pre-wrap;

    @include small-screen() {
      @include Header3Bold();
      padding-inline-end: 0;
    }

    @include mobile-only() {
      @include Header3Bold();
      margin-top: 0;
      margin-bottom: var(--wds-space-400);
      padding-inline-end: 0;
    }
  }

  .marketplaceContent {
    width: 100%;
  }

  .marketplaceEntryDescription {
    position: relative;
    white-space: pre-wrap;
    padding: 10px 0 var(--wds-space-700);
    font-size: 18px !important;
    height: 54px;
    padding-inline-end: var(--wds-space-100);

    @include mobile-only() {
      height: auto;
      white-space: normal;
      padding: var(--wds-space-200) 0 var(--wds-space-400);
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }

  .marketplaceButton {
    text-decoration: underline;
    padding-top: 1px;

    &:hover,
    &:focus {
      color: var(--wds-color-blue-100);
    }

    @include rtl() {
      svg {
        transform: scale(-1, 1);
        margin-right: 3px;
      }
    }
  }

  .marketplaceEntryImageMobileOnly {
    display: none;
    position: relative;
    border-radius: 30px;

    @include mobile-only() {
      aspect-ratio: 131 / 80;
      display: block;
      height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 18px !important;
      overflow: hidden;
    }
  }

  .marketplaceEntryImage {
    position: relative;
    width: 582px;
    height: 291px;
    flex-shrink: 0;
    border-radius: 30px;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    @include small-screen() {
      width: 347px;
      height: 211px;
    }

    @include mobile-only() {
      display: none;
    }
  }
}
