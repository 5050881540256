@import '../../styles/variables.module.scss';

.TopicsSectionWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  background-color: var(--wds-color-white);
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);

  @include small-screen() {
    padding: var(--wds-space-400) var(--wds-space-700) var(--wds-space-400)
      var(--wds-space-700);
  }

  @include mobile-only() {
    padding: var(--wds-space-400);
  }

  .TopicsSectionTitle {
    @include Header1Bold();

    @include small-screen() {
      @include Header3Bold();
    }

    @include mobile-only() {
      @include Header3Bold();
    }
  }

  .TopicsSectionDescription {
    position: relative;
    padding: var(--wds-space-200) 0 var(--wds-space-700);
    font-size: 18px !important;

    @include small-screen() {
      padding: var(--wds-space-200) 0 var(--wds-space-400);
    }

    @include mobile-only() {
      padding: var(--wds-space-100) 0 var(--wds-space-400);
      font-size: 16px !important;
    }
  }

  .TopicsCardsWrapper {
    overflow: hidden;
    width: 100%;
    grid-gap: var(--wds-space-600);

    @include small-screen() {
      grid-gap: var(--wds-space-500);
    }

    @include mobile-only() {
      grid-gap: var(--wds-space-500);
    }
  }
}
