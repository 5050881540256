@import '../../styles/variables.module.scss';

.trendingArticlesWrapper {
  padding: 0 var(--wds-space-1400) var(--wds-space-1400);
  overflow: hidden;
  background-color: var(--wds-color-white);
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: calc(1200px + var(--wds-space-1400) * 2);

  @include small-screen() {
    padding: 0 var(--wds-space-700) var(--wds-space-400);
  }

  @include mobile-only() {
    padding: 0 var(--wds-space-400) var(--wds-space-400);
  }

  .trendingArticlesTitle {
    @include Header1Bold();

    @include small-screen() {
      @include Header3Bold();
    }

    @include mobile-only() {
      @include Header3Bold();
    }
  }

  .trendingArticlesDescription {
    position: relative;
    padding: var(--wds-space-200) 0 var(--wds-space-700);
    font-size: 18px !important;

    @include small-screen() {
      padding: var(--wds-space-200) 0 var(--wds-space-400);
    }

    @include mobile-only() {
      padding: var(--wds-space-100) 0 var(--wds-space-400);
      font-size: 16px !important;
    }
  }

  .trendingArticlesContent {
    box-sizing: border-box;

    @include small-screen() {
      flex-direction: column;
    }

    @include mobile-only() {
      flex-direction: column;
    }
  }

  .trendingArticlesBackground {
    width: 582px;
    height: 436px;
    margin-inline-end: 36px;
    flex-shrink: 0;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    img {
      object-fit: cover;
    }

    @include small-screen() {
      width: 100%;
      height: 375px;
    }

    @include mobile-only() {
      width: 100%;
      height: 200px;
      border-radius: 18px !important;
    }
  }

  .trendingArticlesItems {
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
  }
}
