.SiteSelectorItem {
    &.withScroll {
        .SiteSelectorItemContent {
            padding-inline-end: var(--wds-space-200);
        }
    }

    .SiteSelectorItemContent {
        height: 60px;
        padding: 0;
        padding-inline-end: var(--wds-space-400);
        padding-inline-start: var(--wds-space-400);
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: #eaf7ff;
        }

        .siteImage {
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            background-size: cover;
            background-position: 50%;
            box-sizing: border-box;
            margin-inline-end: var(--wds-space-200);
        }

        &.selected {
            background-color: #f4fafe;

            &:hover,
            &:focus {
                background-color: #eaf7ff;
            }
        }

        .premiumBadge {
            .premiumBadgeText {
                color: var(--wds-color-white);
            }
        }

        .confirmIcon {
            color: var(--wds-color-black-100);
        }
    }
}