@import '../../../styles/variables.module.scss';

.exploreMoreResourcesItemWrapper {
  box-sizing: border-box;
  height: 100%;

  .exploreMoreResourcesItemTitle {
    @include Header4Bold();

    &:hover,
    &:focus {
      color: var(--wds-color-blue-100);
    }
  }

  .exploreMoreResourcesItemDescription {
    padding: var(--wds-space-200) 0 var(--wds-space-400);
  }

  .exploreMoreResourcesItemCtaWrapper {
    flex: 1;
    .exploreMoreResourcesItemCta {
      &:hover,
      &:focus {
        color: var(--wds-color-blue-100);
      }
    }

    @include rtl() {
      svg {
        transform: scale(-1, 1);
        margin-right: 6px;
      }
    }
  }
}
