@import '../../styles/variables.module.scss';

.wrapper {
  width: 100%;
  background-size: cover;
  background-color: var(--wds-color-white);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 108px var(--wds-space-1400) var(--wds-space-1400);
  max-width: calc(1200px + var(--wds-space-1400) * 2);

  @include small-screen() {
    padding: var(--wds-space-1200) var(--wds-space-700) var(--wds-space-700);
  }

  @include mobile-only() {
    padding: var(--wds-space-700) var(--wds-space-400) var(--wds-space-400);
  }

  .title {
    @include Header1Bold();
    text-align: center;

    @include mobile-only() {
      @include Header2Bold();
    }
  }

  .personalizedTitle {
    @include Header1Bold();
    text-align: center;
    margin: 0 0 var(--wds-space-500) 0;

    @include mobile-only() {
      @include Header2Bold();

      :global(.line) {
        display: block;
      }
    }

    :global(.highlight) {
      color: var(--wds-color-blue-100);
    }
  }

  .searchBarWrapper {
    padding-bottom: 108px;
    box-sizing: border-box;

    @include small-screen() {
      padding-bottom: var(--wds-space-1200);
    }

    @include mobile-only() {
      padding-bottom: var(--wds-space-700);
      width: 100%;
    }
  }
}